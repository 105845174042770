import React from "react";
import "./index.css";
import App from "./App";
import { SWRConfig } from "swr";
import { createRoot } from "react-dom/client";

// ReactDOM.render(
//   <React.Fragment>
//     <SWRConfig>
//       <App />
//     </SWRConfig>
//   </React.Fragment>,
//   document.getElementById("root")
// );
const root = createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <SWRConfig>
      <App />
    </SWRConfig>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
