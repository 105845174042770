import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';


const Header = () =>  {
    const auth = useAuth();

    return (
        <div className="header">
        <div className="header-left">
            <div className="menu-icon dw dw-menu"></div>
            <div className="search-toggle-icon dw dw-search2" data-toggle="header_search"></div>
            <div className="header-search">
                
            </div>
        </div>
        <div className="header-right">
            <div className="dashboard-setting user-notification">
                <div className="dropdown">
                    <a className="dropdown-toggle no-arrow" type="button" data-toggle="right-sidebar">
                        <i className="dw dw-settings2"></i>
                    </a>
                </div>
            </div>
            <div className="user-notification">
                <div className="dropdown">
                    <a className="dropdown-toggle no-arrow" href="#" role="button" data-toggle="dropdown">
                        <i className="icon-copy dw dw-notification"></i>
                        <span className="badge notification-active"></span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <div className="notification-list mx-h-350 customscroll">
                            <ul>
                                <li>
                                    <a href="#">
                                        <img src="vendors/images/img.jpg" alt="" />
                                        <h3>John Doe</h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed...</p>
                                    </a>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="user-info-dropdown">
                <div className="dropdown">
                    <a className="dropdown-toggle" href="#" role="button" data-toggle="dropdown">
                        <span className="user-icon">
                            <img src={auth.user?.infos?.photo} alt="" />
                        </span>
                        <span className="user-name">{auth.user?.infos?.name}  {auth.user.infos?.prenom}</span>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right dropdown-menu-icon-list">
                        <Link className="dropdown-item" to={'profil'}><i className="dw dw-user1"></i> Profile</Link>
                        <a type="button" className="dropdown-item"  onClick={() => auth.logout()}><i className="dw dw-help"></i> Deconnecter</a>
                    </div>
                </div>
            </div>
            <div className="github-link">
                <a href="https://github.com/dropways/deskapp" target="_blank"><img src="vendors/images/github.svg" alt="" /></a>
            </div>
        </div>
        </div>

    ) 
}

export default Header;