//import store from './redux/store';
import { ProvideAuth } from "./hooks/use-auth";
import GuestRouter from "./routes/GuestRouter";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <ProvideAuth>
      <BrowserRouter>
        <GuestRouter />
        <ToastContainer limit={1} />
      </BrowserRouter>
    </ProvideAuth>
  );
}

export default App;
