import { NavLink } from "react-router-dom";

let active = {
  color: "blue",
};

const SideBar = () => (
  <>
    <div className="left-side-bar">
      <div className="brand-logo">
        <img
          src="vendors/images/deskapp-logo.svg"
          alt=""
          className="dark-logo"
        />
        <img
          src="vendors/images/deskapp-logo-white.svg"
          alt=""
          className="light-logo"
        />
        <div className="close-sidebar" data-toggle="left-sidebar-close">
          <i className="ion-close-round"></i>
        </div>
      </div>
      <div className="menu-block customscroll">
        <div className="sidebar-menu">
          <ul id="accordion-menu">
            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-house-1"></span>
                <span className="mtext">Home</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-apartment"></span>
                <span className="mtext">Commune</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/commune/create"
                  >
                    Ajouter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/commune"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-copy"></span>
                <span className="mtext">Auto Ecoles</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/auto_create"
                  >
                    Ajouter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/auto"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-library"></span>
                <span className="mtext">Condition</span>
              </a>
              <ul className="submenu">
                {/* <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/condition_create"
                  >
                    Ajouter
                  </NavLink>
                </li> */}
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/condition"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-paper-plane1"></span>
                <span className="mtext">Publicite</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/publicite_create"
                  >
                    Ajouter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/publicite"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-chat3"></span>
                <span className="mtext">Message</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/message"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-edit-2"></span>
                <span className="mtext">Question</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/question_create"
                  >
                    Ajouter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/question"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-invoice"></span>
                <span className="mtext">Themes</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/themes"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-invoice"></span>
                <span className="mtext">Session</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/session"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Type Panneau</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/type_panneau"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Panneau</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/panneau"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Anciens Sujets</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/subject"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Utilisateurs</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/all_users"
                  >
                    Consulter
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/payments"
                  >
                    paiement éffectué
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Envoyer message</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/messages"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="dropdown">
              <a type="button" className="dropdown-toggle">
                <span className="micon dw dw-list3"></span>
                <span className="mtext">Statistique</span>
              </a>
              <ul className="submenu">
                <li>
                  <NavLink
                    style={(navData) => (navData.isActive ? active : {})}
                    to="/statistique"
                  >
                    Consulter
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </>
);

export default SideBar;
