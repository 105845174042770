

const RightSideBar = () => (
    <div className="right-sidebar">
		<div className="sidebar-title">
			<h3 className="weight-600 font-16 text-blue">
				Layout Settings
				<span className="btn-block font-weight-400 font-12">User Interface Settings</span>
			</h3>
			<div className="close-sidebar" data-toggle="right-sidebar-close">
				<i className="icon-copy ion-close-round"></i>
			</div>
		</div>
		<div className="right-sidebar-body customscroll">
			<div className="right-sidebar-body-content">
				<h4 className="weight-600 font-18 pb-10">Header Background</h4>
				<div className="sidebar-btn-group pb-30 mb-10">
					<a type='button' className="btn btn-outline-primary header-white active">White</a>
					<a type='button' className="btn btn-outline-primary header-dark">Dark</a>
				</div>

				<h4 className="weight-600 font-18 pb-10">Sidebar Background</h4>
				<div className="sidebar-btn-group pb-30 mb-10">
					<a type='button' className="btn btn-outline-primary sidebar-light active">White</a>
					<a type='button' className="btn btn-outline-primary sidebar-dark">Dark</a>
				</div>

				<div className="reset-options pt-30 text-center">
					<button className="btn btn-danger" id="reset-settings">Reset Settings</button>
				</div>
			</div>
		</div>
	</div>
)


export default RightSideBar;