import Header from "./headers";
import SideBar from "./side_bar";
import DashboardRouter from "../routes/DashboardRouter";
import RightSideBar from "./rigth_side_bar";

const Layout = () => (
  <>
    <Header />
    <RightSideBar />
    <SideBar />
    <div className="mobile-menu-overlay"></div>
    <div className="main-container">
      <div className="pd-ltr-20">
        <DashboardRouter />
      </div>
    </div>
  </>
);

export default Layout;
