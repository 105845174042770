//import Loadable from 'react-loadable'
//import LoadingOverlay from 'react-loading-overlay'
import Login from "../pages/Login/login";
import Layout from "../layouts/layout";
import { useAuth } from "../hooks/use-auth";

//const PageLoading = () => (<LoadingOverlay active={true} text='Chargement...' spinner />);

// const AsyncLogin = Loadable({
//   loader: () => import('../pages/Login/login'),
//   loading: PageLoading,
// })

// const AsyncLayout = Loadable({
//   loader: () => import('../layouts/layout'),
//   loading: PageLoading,
// })

const GuestRouter = (props: any) => {
  const auth = useAuth();
  return auth.user ? <Layout /> : <Login />;
};

export default GuestRouter;
