import { useState } from "react";
import { useAuth } from "../../hooks/use-auth";
//import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const auth = useAuth();
  //const navigate = useNavigate();

  const navigateToHome = () => (window.location.href = "/");
  //const navigateToHome = () => <Navigate replace to="/" />;

  if (auth.user) {
    navigateToHome();
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const emailIsValid = () => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.length > 0 && re.test(String(email).toLowerCase());
  };

  const performValidation = () => {
    return emailIsValid() && password.length >= 4;
  };

  const handleEmailChange = (e) => {
    setError(false);
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    console.log(e.target.value);
    setError(false);
    setPassword(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!performValidation()) {
      return;
    }
    setLoading(true);
    auth.login(email, password, remember).then((logged) => {
      console.log(logged);
      if (logged) {
        navigateToHome();
        //navigate("/", { replace: true });
      } else {
        setLoading(false);
        setError(true);
        setPassword("");
        toast.error("Vous êtes hors ligne !", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
      }
    });
  };

  return (
    <>
      <div
        className="login-wrap d-flex align-items-center flex-wrap justify-content-center"
        style={{ backgroundImage: 'url("/assets/permis.jpg")' }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-3 col-lg-3">
              {/* <img src="/assets/permis.jpg" width='1090px' alt="" /> */}
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="login-box bg-white box-shadow border-radius-10">
                <div className="login-title">
                  <h2 className="text-center text-primary">Login To DeskApp</h2>
                </div>
                <form>
                  <div className="select-role">
                    <div
                      className="btn-group btn-group-toggle"
                      data-toggle="buttons"
                    >
                      <label className="btn active">
                        <input type="radio" name="options" id="admin" />
                        <div className="icon">
                          <img
                            src="/assets/vendors/images/briefcase.svg"
                            className="svg"
                            alt=""
                          />
                        </div>
                        <span>I'm</span>
                        Manager
                      </label>
                      <label className="btn">
                        <input type="radio" name="options" id="user" />
                        <div className="icon">
                          <img
                            src="/assets/vendors/images/person.svg"
                            className="svg"
                            alt=""
                          />
                        </div>
                        <span>I'm</span>
                        Employee
                      </label>
                    </div>
                  </div>
                  <div className="input-group custom">
                    <input
                      type="email"
                      className={
                        "form-control " +
                        (emailIsValid()
                          ? "is-valid"
                          : email.length
                          ? "is-invalid"
                          : "")
                      }
                      value={email}
                      onChange={handleEmailChange}
                      placeholder="Entrer votre Email"
                    />
                    {/* <input type="text" className="form-control form-control-lg" placeholder="Username" /> */}
                    <div className="input-group-append custom">
                      <span className="input-group-text">
                        <i className="icon-copy dw dw-user1"></i>
                      </span>
                    </div>
                  </div>
                  <div className="input-group custom">
                    <input
                      type="password"
                      className={
                        "form-control " +
                        (password.length >= 4
                          ? "is-valid"
                          : password.length
                          ? "is-invalid"
                          : "")
                      }
                      onChange={handlePasswordChange}
                      placeholder="Entrer votre Mot de Passe"
                    />
                    {/* <input type="password" className="form-control form-control-lg" placeholder="**********" /> */}
                    <div className="input-group-append custom">
                      <span className="input-group-text">
                        <i className="dw dw-padlock1"></i>
                      </span>
                    </div>
                  </div>
                  <div className="row pb-30">
                    <div className="col-6">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck1"
                        />
                        <label className="custom-control-label">Remember</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="forgot-password">
                        <a href="forgot-password.html">Forgot Password</a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="input-group mb-0">
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className="btn btn-primary btn-lg btn-block"
                        >
                          {loading ? (
                            <i className="fa fa-spin fa-spinner"></i>
                          ) : (
                            <small>Connexion</small>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
