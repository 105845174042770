import { Route, Routes } from "react-router-dom";
import Loadable from "react-loadable";
import LoadingOverlay from "react-loading-overlay";

const PageLoading = () => (
  <LoadingOverlay active={true} text="Chargement..." spinner />
);

// - Async Components

const AsyncNoMatch: any = Loadable({
  loader: () => import("../pages/NoMatch"),
  loading: PageLoading,
});

const AsyncHomePage: any = Loadable({
  loader: () => import("../pages/Dashboard/index"),
  loading: PageLoading,
});

const AsyncAutoIndexPage: any = Loadable({
  loader: () => import("../pages/AutoEcole/index"),
  loading: PageLoading,
});

const AsyncCreateAutoPage: any = Loadable({
  loader: () => import("../pages/AutoEcole/create"),
  loading: PageLoading,
});

const AsyncCreateCommunePage: any = Loadable({
  loader: () => import("../pages/Communes/create"),
  loading: PageLoading,
});

// for communes
const AsyncIndexCommunePage: any = Loadable({
  loader: () => import("../pages/Communes/index"),
  loading: PageLoading,
});

const AsyncUpdateCommunePage: any = Loadable({
  loader: () => import("../pages/Communes/update"),
  loading: PageLoading,
});

//for condition
const AsyncCreateConditionPage: any = Loadable({
  loader: () => import("../pages/Condition/create"),
  loading: PageLoading,
});

const AsyncIndexConditionPage: any = Loadable({
  loader: () => import("../pages/Condition/index"),
  loading: PageLoading,
});

//for publicities
const AsyncCreatePublicitePage: any = Loadable({
  loader: () => import("../pages/Publicite/create"),
  loading: PageLoading,
});

const AsyncIndexPublicitePage: any = Loadable({
  loader: () => import("../pages/Publicite/index"),
  loading: PageLoading,
});

const AsyncIndexMessagePage: any = Loadable({
  loader: () => import("../pages/Message/index"),
  loading: PageLoading,
});

//for Message
const AsyncProfilPage: any = Loadable({
  loader: () => import("../pages/profil/index"),
  loading: PageLoading,
});

//for Composant Question
const AsyncComposantQuestionCreatePage: any = Loadable({
  loader: () => import("../pages/Testing/questions/create"),
  loading: PageLoading,
});
const AsyncComposantQuestionUpdatePage: any = Loadable({
  loader: () => import("../pages/Testing/questions/update"),
  loading: PageLoading,
});
const AsyncComposantQuestionAllPage: any = Loadable({
  loader: () => import("../pages/Testing/questions/index"),
  loading: PageLoading,
});

//themes
const AsyncComposantThemeAllPage: any = Loadable({
  loader: () => import("../pages/Testing/theme/index"),
  loading: PageLoading,
});

//Type Panneau
const AsyncComposantTypePanneauAllPage: any = Loadable({
  loader: () => import("../pages/TypePanneaux/index"),
  loading: PageLoading,
});

//Panneau
const AsyncComposantPanneauAllPage: any = Loadable({
  loader: () => import("../pages/Panneau/index"),
  loading: PageLoading,
});

//Session
const AsyncComposantSessionAllPage: any = Loadable({
  loader: () => import("../pages/Session/index"),
  loading: PageLoading,
});

//users
const AsyncComposantUsersPage: any = Loadable({
  loader: () => import("../pages/Users"),
  loading: PageLoading,
});

//Paymement
const AsyncComposantPaymementPage: any = Loadable({
  loader: () => import("../pages/Payment"),
  loading: PageLoading,
});

//Messages
const AsyncComposantMessagesPage: any = Loadable({
  loader: () => import("../pages/Messages/index"),
  loading: PageLoading,
});

//oldSubject
const AsyncComposantoldSubjectPage: any = Loadable({
  loader: () => import("../pages/OldSubject"),
  loading: PageLoading,
});

//Statistique
const AsyncComposantoldStatistiquePage: any = Loadable({
  loader: () => import("../pages/Statistique"),
  loading: PageLoading,
});

const DashboardRouter = (_) => {
  return (
    <Routes>
      <Route path="/" element={<AsyncHomePage />} />

      <Route path="auto_create" element={<AsyncCreateAutoPage />} />

      <Route path="auto" element={<AsyncAutoIndexPage />} />

      <Route path="profil" element={<AsyncProfilPage />} />

      <Route path="condition" element={<AsyncIndexConditionPage />} />

      <Route path="condition_create" element={<AsyncCreateConditionPage />} />

      <Route path="publicite" element={<AsyncIndexPublicitePage />} />

      <Route path="publicite_create" element={<AsyncCreatePublicitePage />} />

      <Route path="message" element={<AsyncIndexMessagePage />} />

      <Route path="commune" element={<AsyncIndexCommunePage />} />

      <Route path="commune/create" element={<AsyncCreateCommunePage />} />

      <Route
        path="question_create"
        element={<AsyncComposantQuestionCreatePage />}
      />

      <Route path="question" element={<AsyncComposantQuestionAllPage />} />

      <Route
        path="type_panneau"
        element={<AsyncComposantTypePanneauAllPage />}
      />

      <Route path="panneau" element={<AsyncComposantPanneauAllPage />} />

      <Route path="session" element={<AsyncComposantSessionAllPage />} />

      <Route path="all_users" element={<AsyncComposantUsersPage />} />

      <Route path="payments" element={<AsyncComposantPaymementPage />} />

      <Route path="messages" element={<AsyncComposantMessagesPage />} />

      <Route
        path="question/:id"
        element={<AsyncComposantQuestionUpdatePage />}
      />

      <Route path="commune/:id" element={<AsyncUpdateCommunePage />} />

      <Route path="themes" element={<AsyncComposantThemeAllPage />} />

      <Route path="subject" element={<AsyncComposantoldSubjectPage />} />

      <Route
        path="statistique"
        element={<AsyncComposantoldStatistiquePage />}
      />

      <Route path="*" element={<AsyncNoMatch />} />
    </Routes>
  );
};

export default DashboardRouter;
